/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import PagePath from "../../../../../res/values/PagePath";
import HeaderItemSubMenu from "./HeaderItemSubMenu";
import { useSelector } from "react-redux";
import HeaderItemSubMenuLogoutPlayer from "../../../../../app/pages/AdminBase/LogoutPlayer/HeaderItemSubMenuLogoutPlayer";
import { BaseViewModel } from "../../../../../app/component/BaseViewModel";

export function HeaderMenu({ layoutProps }) {
  const baseViewModel = new BaseViewModel();
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const { isSupervisor } = useSelector(({ auth }) => ({
    isSupervisor: auth.role === "SUPERVISOR",
  }));

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            PagePath.SETUP
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to={PagePath.SETUP}>
            <span className="menu-text">Setup</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {/*begin::2 Level*/}
              <HeaderItemSubMenu path={PagePath.LEAGUE} title="League" />
              <HeaderItemSubMenu path={PagePath.TEAM} title="Team" />
              <HeaderItemSubMenu path={PagePath.MATCH} title="Match" />
              {isSupervisor ? (
                <>
                  <HeaderItemSubMenu path={PagePath.ALLUP} title="All Up" />
                  <HeaderItemSubMenu
                    path={PagePath.BET_SETTING}
                    title="Bet Setting"
                  />
                </>
              ) : null}
              <HeaderItemSubMenu
                path={PagePath.PACKAGE_SETTING}
                title="Package Setting"
              />
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {isSupervisor ? (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              PagePath.ODDS_BASE
            )}`}
          >
            <NavLink className="menu-link menu-toggle" to={PagePath.ODDS_BASE}>
              <span className="menu-text">Odds</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {/*begin::2 Level*/}
                <HeaderItemSubMenu path={PagePath.ODDS} title="Odds" />
                <HeaderItemSubMenu path={PagePath.TG_OE} title="TG/OE" />
                <HeaderItemSubMenu
                  path={PagePath.CORRECT_SCORE}
                  title="Correct Score"
                />
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>
        ) : null}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            PagePath.BETS
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to={PagePath.BETS}>
            <span className="menu-text">Bets</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {/*begin::2 Level*/}
              <HeaderItemSubMenu
                path={PagePath.RUN_MANAGER}
                title="Run Manager"
              />
              <HeaderItemSubMenu path={PagePath.LEAGUE_RUN_MANAGER} title="League Run Manager" />
              <HeaderItemSubMenu
                path={PagePath.WAITING_TICKET}
                title="Waiting Ticket"
              />
              <HeaderItemSubMenu
                path={PagePath.PARLAY_REFUND}
                title="Parlay Refund"
              />
              <HeaderItemSubMenu
                path={PagePath.CANCEL_TICKET}
                title="Cancel Ticket"
              />
              <HeaderItemSubMenu
                path={PagePath.RECENT_TICKET}
                title="Recent Ticket"
              />
              <HeaderItemSubMenu
                path={PagePath.BIG_DEPOSIT}
                title="Big Deposit"
              />
              <HeaderItemSubMenu
                path={PagePath.BIG_WINNER}
                title="Big Winner"
              />
              <HeaderItemSubMenu
                path={PagePath.MEMBER_BETS}
                title="Member Bets"
              />
              <HeaderItemSubMenu path={PagePath.ALL_BETS} title="All Bets" />
              <HeaderItemSubMenu path={PagePath.ALL_BETS_NATIVE} title="All Bets New" />
              <HeaderItemSubMenu path={PagePath.ALL_PARLAYS} title="All Parlays" />
              <HeaderItemSubMenu
                path={PagePath.OUTSTANDING}
                title="Outstanding"
              />
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            PagePath.RESULT
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to={PagePath.RESULT}>
            <span className="menu-text">Result</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {/*begin::2 Level*/}
              <HeaderItemSubMenu path={PagePath.UPDATE_RESULT} title="Update" />
              {isSupervisor ? (
                <HeaderItemSubMenu path={PagePath.ROLLBACK} title="Rollback" />
              ) : null}
              {isSupervisor ? (
                <HeaderItemSubMenu
                  path={PagePath.ROLLBACK_REQUEST_LOGS}
                  title="Rollback Request Logs"
                />
              ) : null}
              <HeaderItemSubMenu
                path={PagePath.NEGATIVE_MEMBER}
                title="Negative Member"
              />
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            PagePath.ANNOUNCEMENT_BASE
          )}`}
        >
          <NavLink
            className="menu-link menu-toggle"
            to={PagePath.ANNOUNCEMENT_BASE}
          >
            <span className="menu-text">Announcement</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {/*begin::2 Level*/}
              <HeaderItemSubMenu
                path={PagePath.ANNOUNCEMENT_MESSAGES}
                title="Messages"
              />
              <HeaderItemSubMenu
                path={PagePath.ANNOUNCEMENT_CONTENT}
                title="Content"
              />
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            PagePath.SPIDER
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to={PagePath.SPIDER}>
            <span className="menu-text">Spider</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {/*begin::2 Level*/}
              <HeaderItemSubMenu path={PagePath.SPIDER_TASK} title="Task" />
              {/* <HeaderItemSubMenu path={PagePath.SBOTOP_PARAM} title="Sbotop Param" /> */}
              <HeaderItemSubMenu path={PagePath.SPIDER_SPEC} title="Spec" />
              <HeaderItemSubMenu path={PagePath.IP_WHITELIST} title="Proxy IP Whitelist" />
              <HeaderItemSubMenu path={PagePath.SPIDER_SPECIAL_LEAGUE} title="Special League" />
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            PagePath.BLACKLIST
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to={PagePath.BLACKLIST}>
            <span className="menu-text">Blacklist</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {/*begin::2 Level*/}
              <HeaderItemSubMenu path={PagePath.BLACKLIST_AGENT} title="Agent" />
              <HeaderItemSubMenu path={PagePath.BLACKLIST_PLAYER} title="Player" />
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {isSupervisor ? (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              PagePath.ADMINBASE
            )}`}
          >
            <NavLink className="menu-link menu-toggle" to={PagePath.ADMINBASE}>
              <span className="menu-text">Admin</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {/*begin::2 Level*/}
                <HeaderItemSubMenu path={PagePath.ADMIN} title="Users" />
                <HeaderItemSubMenu
                  path={PagePath.MAINTENANCE}
                  title="Maintenance"
                />
                <HeaderItemSubMenu path={PagePath.SETTINGS} title="Settings" />
                <HeaderItemSubMenu
                  path={PagePath.OPERATION_LOG}
                  title="Operation Log"
                />
                <HeaderItemSubMenuLogoutPlayer
                  title="Force Logout All Players"
                  viewModel={baseViewModel}
                />
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>
        ) : null}
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
